export interface IErosApiConfig {
  getApiUrl(): string;
}

export abstract class AbstractErosApiConfig implements IErosApiConfig {
  abstract getApiUrl(): string;
}

export function throwErrorIfErosApiConfigIsMissing(
  config: IErosApiConfig | null
) {
  if (!config) {
    throw new Error(
      '[App Error]: ErosApiConfig is not provided, please use ErosApiModule.forRoot method to provide concrete implementation of AbstractErosApiConfig class!'
    );
  }
}
