import {
  ModuleWithProviders,
  ClassProvider,
  Inject,
  NgModule,
  Optional,
  Type
} from '@angular/core';

import {
  AbstractErosApiConfig,
  throwErrorIfErosApiConfigIsMissing
} from './eros-api-config';

@NgModule()
export class ErosApiModule {
  constructor(
    @Optional()
    @Inject(AbstractErosApiConfig)
    private config: AbstractErosApiConfig
  ) {
    throwErrorIfErosApiConfigIsMissing(config);
  }

  static forRoot(
    erosApiConfigClassCtor: Type<AbstractErosApiConfig>
  ): ModuleWithProviders<ErosApiModule> {
    return {
      ngModule: ErosApiModule,
      providers: [
        {
          provide: AbstractErosApiConfig,
          useClass: erosApiConfigClassCtor
        } as ClassProvider
      ]
    };
  }
}
